import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { PATHS } from "util/appConstants";
import {
  selectCustomer,
  selectCustomerStatus,
  getCustomer,
  addCustomer,
} from "redux/slices/customerSlice";
import { getTours, selectTours } from "redux/slices/tourSlice";
import {
  getZipcodes,
  selectZipcodes,
  selectZipcodeStatus,
} from "redux/slices/zipcodeSlice";
import CustomerForm from "components/Customers/form";
import Navbar from "components/Navbar";
import LightLayout from "components/Shared/LightLayout";
import CustomersNavbar from "components/Masterbar/CustomersBar";

const currentAction = "ADD";

const AddWithExistingCustomerData = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const loading = useSelector(selectCustomerStatus);
  const loadingZipcodes = useSelector(selectZipcodeStatus);
  const zipcodes = useSelector(selectZipcodes);
  let customer = useSelector(selectCustomer);
  const tours = useSelector(selectTours);

  useEffect(() => {
    if (id && !loading) {
      dispatch(getCustomer(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!tours.length) {
      dispatch(getTours());
    }
  }, [dispatch, tours]);

  useEffect(() => {
    if (!loadingZipcodes) {
      dispatch(getZipcodes());
    }
  }, []);

  const onSubmit = async (payload) => {
    await dispatch(addCustomer(payload)).then((res) =>
      res !== undefined ? history.push(PATHS.customers.root) : ""
    );
  };
  if (customer) {
    const { number, ...updatedCustomer } = customer;
    customer = updatedCustomer;
  }

  return (
    <>
      <Navbar />
      <CustomersNavbar />
      <LightLayout doublebar loading={loading || !customer}>
        <CustomerForm
          initialValues={customer}
          onSubmit={onSubmit}
          action={currentAction}
          tourList={tours}
          zipcodes={zipcodes}
        />
      </LightLayout>
    </>
  );
};
export default AddWithExistingCustomerData;
